/* General body and container styles */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f5f5f5;
  margin: 0;
}

.insumos-page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Title styles */
.page-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Top section styles */
.top-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px; /* Spacing between items */
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align buttons */
  gap: 10px; /* Space between buttons */
  width: 250px;
}

.view-toggle-button {
  background-color: #3bcf00;
  color: white;
  padding: 10px 20px;
  width: 100%; /* Full width of the container */
  max-width: 200px; /* Max width to maintain size */
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.view-toggle-button:hover {
  background-color: #2a9d00;
}

/* AdSense container styles */
.adsense {
  display: block;
  margin: 20px auto;
  max-width: 50vw;
}

.ads-sticky-left,
.ads-sticky-right {
  position: fixed;
  top: 50vh;
  transform: translateY(-50%);
  width: 15vw;
  max-height: 80vh;
  height: 80vh;
  z-index: 1000;
  padding: 10px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}

.ads-sticky-left {
  left: 0;
}

.ads-sticky-right {
  right: 0;
}

/* No data message styles */
.no-data-message {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
}

/* Table styles */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.data-table th {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.data-table th:hover {
  background-color: #0056b3;
}

.data-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tbody tr:hover {
  background-color: #f1f1f1;
}


.table-settings {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 1rem auto;
}

.export-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #0056b3;
}

.pareto-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.pareto-limit-inputs,
.pareto-filters {
  display: flex;
  gap: 1rem;
}

.pareto-label {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  color: #333;
  font-weight: 600;
}

.pareto-input {
  width: 80px;
  padding: 0.4rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 0.3rem;
}

.pareto-filters {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.filter-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
}

.filter-checkbox {
  margin-right: 0.3rem;
}

.filter-label {
  font-weight: 500;
}
