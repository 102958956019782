/* Container for the header */
.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 30px;
}



/* Logo styling */
.logo a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Navigation styling */
.nav-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.nav-links li {
  display: inline-block;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

.nav-links a:hover {
  color: #ffcc00;
}

/* Ensure the header and main content are separate */

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Main content should not affect the header */
.main-content {
  flex-grow: 1;
  
  padding: 10px; /* Adjust as needed */
}