/* Container for the homepage */
.homepage-container {
  text-align: center;
  padding: 20px;
  background-color: #f3f4f6;
  font-family: 'Arial', sans-serif;
}

/* Header styling */
.header {
  margin-bottom: 40px;
}

.header h1 {
  font-size: 3rem;
  color: #1a1a1a;
  font-weight: bold;
}

.header p {
  font-size: 1.25rem;
  color: #666;
  margin-top: 10px;
}

/* Search bar styling */
.search-container {
  margin: 10px;
}

.search-container input {
  padding: 15px;
  width: 100%;
  max-width: 600px;
  font-size: 1.1rem;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-container input:focus {
  outline: none;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Services grid styling */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  padding: 0 20px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .services-grid {
      grid-template-columns: 1fr;
  }
}

/* Service card styling */
.service-card {
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  max-width: 400px;
  margin: auto; /* Center cards on smaller screens */
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.service-card h3 {
  color: #2c3e50;
  font-size: 1.75rem;
  margin: 5px !important;
}

.service-card p {
  font-size: 1rem;
  color: #777;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Link button styling */
.service-card .service-link {
  display: inline-block;
  padding: 12px 25px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.service-card .service-link:hover {
  background-color: #0056b3;
}

/* Responsive styling for small screens */
@media (max-width: 600px) {
  .service-card {
    width: 90%;; /* Make the card take up more space on smaller screens */
  }
}
