
.import-analyzer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f7f7;
}

.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.functional-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.input-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  margin: 10px 0; /* Optional margin for spacing */
}

.input-title {
  text-align: center; /* Center the title text */
  margin-bottom: 5px; /* Space between title and inputs */
}

.column-indexes {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  gap: 0;
  margin: 15px auto; /* Center the container itself */
  max-width: 200px;
}

.column-indexes label {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center label and input */
  margin: 0;
}

.column-indexes input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
}

.upload-section {
  display: flex;
  flex-direction: column; /* Stack file upload and paste area */
  flex: 1; /* Adjust as necessary */
  gap: 15px;
}



.action-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.action-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s; /* Smooth transition */
}

.action-section button:hover {
  background-color: #0056b3;
}

.table-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
}

.data-table td button {
  padding: 6px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.data-table td button:hover {
  background-color: #c82333;
}

/* Styles for FileUpload and PasteArea components */
.file-upload-container,
.paste-area {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.file-upload-container input[type="file"] {
  margin: 0;
  padding: 10px;
}

/* Styles for the file upload input area */
.file-upload-modal {
  max-width: 500px; /* Maximum width for the input area */
  margin: 20px auto; /* Center the input area */
  text-align: center; /* Center text */
}

/* Styles for the file input */
.file-upload-input {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  width: 100%; /* Full width */
  cursor: pointer; /* Pointer cursor */
}

/* Styles for buttons */
.file-upload-button {
  margin-top: 20px; /* Increased margin for spacing */
  padding: 10px 15px; /* Padding for button */
  border: none; /* Remove default border */
  background-color: #007bff; /* Bootstrap blue color */
  color: white; /* White text */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition */
}

/* Button hover effect */
.file-upload-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Modal styling */
.file-upload-modal {
  position: fixed; /* Fixed position for the modal */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position */
  background-color: white; /* Modal background color */
  padding: 20px; /* Padding for the modal */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  z-index: 1000; /* Ensure modal is on top */
  width: 90%; /* Responsive width */
  max-width: 400px; /* Max width for larger screens */
}

/* Center the select dropdown */
.file-upload-modal select {
  margin-top: 10px; /* Margin for spacing above the select */
  display: block; /* Ensure it's a block element */
  width: 100%; /* Full width */
}

/* Modal overlay for dimming the background */
.modal-overlay {
  position: fixed; /* Fixed position for overlay */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Below the modal */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .file-upload-modal {
      width: 90%; /* Full width on smaller screens */
      padding: 15px; /* Less padding */
  }
}


h3 {
  margin-bottom: 20px;
  color: #333;
}

.input-container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

input[type="text"],
input[type="number"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.filter-container {
  text-align: center; /* Centers the title and labels horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-title {
  font-size: 1.5em; /* Adjust font size as desired */
  margin-bottom: 10px; /* Add spacing below the title */
}

.ad-body {
  align-items: center;
}