/* Entire page layout */
.sinapi-search-expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;  /* Ensures padding is included in width calculations */
  margin: 0;  /* Reset any global margin */
}

/* Header Center */
.sinapi-search-expanded .header-center {

  justify-content: center;
  align-items: center;
  width: 90vw;
  margin: 0 auto;  /* Ensures it's centered */
  text-align: center;
  padding: 10px 0;
  height: auto;  /* Let the height adjust based on content */
}

/* Button styles inside header-center */
.sinapi-search-expanded .header-center button {
  width: 250px;
  margin: 15px;
  background-color: #2196F3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Center input filter box */
.sinapi-search-expanded .center-input-container {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.sinapi-search-expanded .center-input-container input {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 50%;
  box-sizing: border-box;  /* Ensures the input width doesn't overflow */
}

/* Table container layout */
.sinapi-search-expanded .table-container {

  align-items: center;
  width: 60%;  /* Use full width available */
  justify-content: center;
  box-sizing: border-box;  /* Ensures padding and borders are included in width calculations */
}

/* Scrollable area for tables */
.sinapi-search-expanded .super-container {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;  /* Ensures padding/borders do not affect centering */
}

.sinapi-search-expanded .scrollable {
  width: 100%;
  max-width: 90vw; /* Prevents overflow but ensures the table remains centered */
}

/* Sticky adsense sections */
.sinapi-search-expanded .adsense-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.sinapi-search-expanded .ads-sticky-left,
.sinapi-search-expanded .ads-sticky-right {
  position: fixed;
  top: 50vh;
  transform: translateY(-50%);
  width: 15vw;
  max-height: 80vh;
  height: 80vh;
  z-index: 1000;
  padding: 10px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
}

.ads-sticky-left {
  left: 0;
}

.ads-sticky-right {
  right: 0;
}

/* Pagination and filter options (unchanged) */
.sinapi-search-expanded .pagination {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.sinapi-search-expanded .pagination button {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
}

.sinapi-search-expanded .pagination button.active {
  background-color: #007bff;
  color: white;
}

.sinapi-search-expanded .filters {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sinapi-search-expanded .filter-options {
  display: flex;
  gap: 15px;
  align-items: center;
}

.sinapi-search-expanded .filter-options label {
  font-size: 0.9rem;
}

/* Loading and No Data messages (unchanged) */
.sinapi-search-expanded .no-data-message,
.sinapi-search-expanded .loading-overlay {
  text-align: center;
  font-size: 1.2rem;
  color: #333;
}

.sinapi-search-expanded .loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  border-radius: 5px;
}
