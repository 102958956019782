/* styles/SinapiViewer.css */

h1 {
  margin: 0;
  padding: 0;
}

.header-content {
  display: flex;
  flex-direction: row; /* Keep elements in a row on larger screens */
  align-items: center; /* Center items vertically */
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  justify-content: space-between; /* Space out the sections */
}

.header-left {
  flex: 0 0 250px; /* Fixed width of 250px */
  margin-right: 5px; /* Small space between left and center */
  text-align: left; /* Ensure left-aligned text */
}

.header-center {
  flex: 0 0 250px; /* Fixed width of 300px */
  text-align: left; /* Align text to the left */
}

.header-right {
  flex: 1; /* Takes the remaining space */
  margin-left: auto; /* Pushes the right section to the far right */
}

/* Media query for screens narrower than 900px */
@media (max-width: 900px) {
  .header-content {
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center content vertically */
  }

  .header-left {
    flex: 0 0 250px; /* Maintain fixed width */
    margin-bottom: 5px; /* Small space below left */
  }

  .header-center {
    flex: 0 0 300px; /* Maintain fixed width */
    margin-bottom: 5px; /* Space below center */
  }

  .header-right {
    flex: 1; /* Takes the remaining space */
    margin-left: 0; /* Reset left margin on small screens */
    text-align: center; /* Center content in the right section */
  }
}

/* Remaining styles unchanged... */

input[type="text"] {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: 2px solid #4CAF50;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  outline: none;
  transition: border-color 0.3s;
}

input[type="text"]:focus {
  border-color: #2e7d32;
  box-shadow: 0 0 5px rgba(46, 125, 50, 0.5);
}

/* Pagination styles */
.pagination {
  margin-top: 10px;
  text-align: center;
}

.pagination button {
  padding: 8px;
  margin: 0 3px;
  border: 1px solid #4CAF50;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: black;
}

.pagination button:hover, .pagination button.active {
  background-color: #4CAF50;
  color: white;
}

.super-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Table container */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}



.table-container {
  position: relative;

  height: calc(80vh - 350px);
  overflow-y: auto;
  border: 1px solid black;
  font-size: 15px;
  width: 90%;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th, .table-container td {
  border: 1px solid #ddd;
  padding: 5px;
}

thead {
  background-color: #2d90c9;
  color: white;
}

tbody {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #e9e9e9;
}

.composition {
  background-color: #f9ff8a;
}

/* Component table */
.component-table {
  margin-top: 10px;
}

.component-table th {
  background-color: #4CAF50;
  color: white;
}

.component-table tbody {
  background-color: #c9fbcc;
}

.expand-button {
  cursor: pointer;
  color: #4CAF50;
}

.expand-button:hover {
  color: #333;
}

.component-row {
  background-color: rgb(255, 255, 169);
}

.collapse {
  transition: height 0.3s ease;
}

/* Button group */
.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
}

.button-group button {
  width: 100%;
  margin: 5px 0; /* Adjusts spacing between buttons */

  background-color: #2196F3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.center-input-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.center-input-container input {
  width: 100%;
  max-width: 300px;
}


.button-group button:hover {
  transform: translateY(-3px);
}

.button-group button.active {
  background-color: #4CAF50;
  transform: none;
}

.button-group button.active:hover {
  background-color: #00a906;
}

.button-group button:focus {
  outline: none;
}

.button-group button:active {
  transform: translateY(1px);
}

/* Select container */
.select-container {
  margin-bottom: 15px;
}

.select-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.select-container select, .select-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

h2 {
  text-align: center;
}

/* Error message */
.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

/* Input container */
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.select-field {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.search-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
}

.search-button:hover {
  background-color: #0056b3;
}

.filters {
  display: flex;
  justify-content: center; /* Center the filters container */
  margin: 5px 0; /* Add spacing around the filters */
}

.filter-options {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  justify-content: center;
  gap: 15px; /* Space between each filter group */
  
}

.filter-options label {
  display: flex;
  align-items: center; /* Align checkbox and text in a row */
  gap: 10px; /* Space between the checkbox and the label text */
  background: #f8f8f8; /* Light background for the label */
  border: 1px solid #ccc; /* Subtle border around each filter group */
  border-radius: 8px; /* Rounded corners for a modern look */
  padding: 5px; /* Add padding inside the label for spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a slight shadow */
  margin: 5px;
}

@media (max-width: 900px) {
  .filter-options {
    flex-direction: column; /* Stack items vertically on small screens */
  }

  .filter-options label {
    width: 100%; /* Make labels full width for better visibility */
    justify-content: center; /* Center the label text */
  }
}
