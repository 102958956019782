/* styles/timelineManager.css or a separate CSS file for modals */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.modal h3 {
  margin-top: 0;
}

.modal label {
  display: block;
  margin-top: 10px;
}

.modal button {
  margin: 15px;
  padding: 10px;
  background-color: royalblue;
}

.modal button:hover {

  background-color: rgb(44, 195, 245);
}


/* General styling for the timeline manager */
.timeline-data {
  display: flex; /* Keep the services and graph in a row */
  align-items: flex-start; /* Align items to the start of the flex container */
  gap: 20px; /* Space between the services and the timeline */
  overflow-x: hidden; /* Enable horizontal scrolling for the entire container */
  
}

.timeline-controller {
  width: 100%; /* Full width */
  height: 100px;
  background-color: #f0f0f0; /* Add background color */
  position: sticky; /* Stick to the top */
  top: 0;
  z-index: 20; /* Ensure it stays on top */
}

.services-list {
  width: 800px; /* Adjust width as needed */
  position: sticky; /* Make the services list sticky */
  left: 0; /* Stick to the left of the viewport */
  top: 0; /* Stick to the top of the viewport */
  background-color: white; /* Background color to prevent overlap */
  z-index: 10; /* Ensure it stays above other elements */
  height: 100vh; /* Ensure it takes full height if necessary */
}

.service, .timeline-bar-container {
  margin-top: 10px; /* No margin to avoid overlap */

  display: flex;
  align-items: center; /* Center items vertically */
  gap: 10px;
  padding: 10px; /* Padding inside each service */
  background-color: lightgray; /* Background for service items */
  height: 150px; /* Fixed height for alignment */

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9; /* Light background */
  color: #333; /* Dark text color */
  transition: background-color 0.3s ease, transform 0.2s ease;
}


.description {
  flex: 10; /* Equal flex for each item in service */
}

.measure,
.quantity {
  flex: 1; /* Equal flex for each item in service */
}

.timeline-graph {
  display: flex; /* Use flex to align bars */
  flex-direction: column; /* Ensure each row of bars is aligned vertically */
  justify-content: flex-start; /* Align to the start of the flex container */
  height: 100%; /* Set a height for the timeline graph */
  width: calc(100% - 320px); /* Adjust width to accommodate services-list width and gap */
  background-color: #e0e0e0; /* Background for the timeline graph */
  overflow-x: auto; /* Allow horizontal scrolling for the graph */
  
}

/* Individual bars within the container */
.timeline-bar-container {
  position: relative; /* Required for absolute positioning of bars */
  width: 100% !important;
  height: 150px; /* Set height to match service height for alignment */
}

/* Individual bars within the container */
.timeline-bar {
  height: 120px; /* Height of each bar */
  background-color: #4caf50; /* Color of the bars */
  color: white; /* Text color */
  text-align: center; /* Center text horizontally */
  line-height: 20px; /* Center text vertically within the bar */
  border-radius: 4px; /* Rounded corners for bars */
  position: absolute; /* Position absolute to allow centering in parent */
  top: 50%; /* Move down to middle of container */
  transform: translateY(-50%); /* Center bar vertically within the container */
}

